<template>
	<div class="row-space-tbf detailed" v-if="loaded">
		<div class="space-left"></div>
        <component :is="activeTab" >
            <div v-if="$resize && $mq.above(1201)" class="tabs">
                <a class="title-tab" :href="`${procedures_fe_link}/reports?type=users`">{{ $t('reports.users') }}</a>
                <router-link class="title-tab" :class="{active: activeTab == 'users'}" :to="{ name: 'reports', query: {type: 'users'}}">{{ $t('reports.responsibilities') }}</router-link>
                <router-link class="title-tab" :class="{active: activeTab == 'activities'}" :to="{ name: 'reports', query: {type: 'activities'}}">{{ $t('reports.activities') }}</router-link>
                <a class="title-tab" :href="`${procedures_fe_link}/reports?type=processes`">{{ $t('reports.processes') }}</a>
                <a class="title-tab" :href="`${procedures_fe_link}/reports?type=procedures`">{{ $t('reports.procedures') }}</a>
                <a class="title-tab" :href="`${procedures_fe_link}/reports?type=improvements`">{{ $t('reports.improvement_requests') }}</a>
                <a class="title-tab" :href="`${objectives_fe_link}/reports`">{{ $t('reports.promises') }}</a>
            </div>
            <div v-if="$resize && $mq.below(1200)" class="actions-dropdown">
                <div class="filter-dropdown dropdown">
                    <button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="text" v-if="activeTab == 'users'">{{ $t('reports.responsibilities') }}</span>
                        <span class="text" v-else-if="activeTab == 'activities'">{{ $t('reports.activities') }}</span>
                        <div class="icon-filter"><icon-arrow /></div>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownFilter">
                        <a class="dropdown-item" :href="`${procedures_fe_link}/reports?type=users`">
                            <div class="checkbox">
                                <span class="text">{{ $t('reports.users') }}</span>
                            </div>
                        </a>
                        <router-link class="dropdown-item" :to="{ name: 'reports', query: {type: 'users'}}">
                            <div class="checkbox">
                                <span class="text">{{ $t('reports.responsibilities') }}</span>
                            </div>
                        </router-link>
                        <router-link class="dropdown-item" :to="{ name: 'reports', query: {type: 'activities'}}">
                            <div class="checkbox">
                                <span class="text">{{ $t('reports.activities') }}</span>
                            </div>
                        </router-link>
                        <a class="dropdown-item" :href="`${procedures_fe_link}/reports?type=processes`">
                            <div class="checkbox">
                                <span class="text">{{ $t('reports.processes') }}</span>
                            </div>
                        </a>
                        <a class="dropdown-item" :href="`${procedures_fe_link}/reports?type=procedures`">
                            <div class="checkbox">
                                <span class="text">{{ $t('reports.procedures') }}</span>
                            </div>
                        </a>
                        <a class="dropdown-item" :href="`${procedures_fe_link}/reports?type=improvements`">
                            <div class="checkbox">
                                <span class="text">{{ $t('reports.improvement_requests') }}</span>
                            </div>
                        </a>
                        <a class="dropdown-item" :href="`${objectives_fe_link}/reports`">
                            <div class="checkbox">
                                <span class="text">{{ $t('reports.promises') }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </component>

		<div class="space-right"></div>
	</div>
</template>

<script>
	import Users from './Users'
    import Activities from './Activities'
    import IconArrow from '../../Icons/Arrow'

    export default {
    	components: {
            Users,
            Activities,
            IconArrow
        },
        watch:{
			$route (to, from){
				this.activeTab = this.$route.query.type ? this.$route.query.type : 'users'
            }
        },
        data() {
            return {
            	activeTab: 'users',
                loaded: false,
                procedures_fe_link: process.env.VUE_APP_PROCEDURES_FE_LINK,
                objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK
            }
        },
        async mounted(){
            if(this.$route.query.type){
				this.activeTab = this.$route.query.type
                this.$root.$emit('change_'+this.$route.query.type);
			}

            setTimeout(() => {
                var title = this.$t('navbar.reports');
                this.$root.$emit("navbar_title", title);
                setTimeout(() => {
                    this.loaded = true
                }, 0)
            }, 0)
        },
        methods: {
        	changeTab(value){
        		this.activeTab = value
        	}
        }
    }
</script>